body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*(C) Nicholas Ruppel Nov. 13, 2017*/
/*(C) Nicholas Ruppel Nov. 13, 2017*/

html, body,  span, applet, object, iframe, h2, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, div, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	overflow-x: hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}


/* http://www.paulirish.com/2012/box-sizing-border-box-ftw/ (2015/04/28) */

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

/* Base */
body {
	font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
	font-size: 14px;
	line-height: 21px;
	background-color: #000;
}

h3{
	text-align: center;
}
h1{
	margin: 0 auto;
	padding-top: 1em;
	text-align: center;
}
header {
background-color: white;
border-bottom: 5px solid black;
height: 131px;
width:100vw;

}
footer{
	width: 100vw;
	height: 75px;
	background-color: #800000;
	border-top: 5px solid black;
	position: absolute;
}
a{
	text-decoration:none;
	padding-top:6.5px;
	padding-bottom:15.5px;

}

 a:hover {
 	fill:white;
 }

span{

	color:white;
	display:inline-block;
	text-align: center;
	font-family: 'Press Start 2P', cursive;
	font-size:10px;

}
li{
	height:96.1px;
	width:100px;
}
svg{
	width: 26px;
	height: 26px;
}
img {
	width: 300px;
	height: 400px;
	margin: 1%;

}

/* Menu styles - note: no extra browser prefixes */
.main-nav {
	width: 105px;
	background-color: #800000;
	padding: 21px 0;
	height: 125vh;
	text-align: center;
	border-right: 5px solid black;
	position: absolute;



}
.main-nav > ul {

}
.main-nav > ul > li {
	position: relative;


}
.main-nav > ul > li > a {
	display: block;
	text-decoration: none;
	color: rgba(255,255,255,0.75);
	padding: 21px;
	transition: 0.4s color, 0.4s -webkit-transform;
	transition: 0.4s transform, 0.4s color;
	transition: 0.4s transform, 0.4s color, 0.4s -webkit-transform;
}
.main-nav > ul > li > a:hover {
	-webkit-transform: translateY(3px);
	        transform: translateY(3px);
	color: #FFF;
}
.main-nav > ul > li > a > .fake-svg {
	display: block;
	width: 40px;
	height: 40px;
	background-color: rgba(255,255,255,0.2);
	margin: 0 auto;
	transition: 0.4s background-color;
}
.main-nav > ul > li > a:hover > .fake-svg {
	background-color: rgba(255,255,255,0.4);
}
.main-nav > ul > li > a > span:not(.fake-svg) {
	display: block;
	-webkit-transform: translateX(-200px);
	        transform: translateX(-200px);
	transition: 0.4s -webkit-transform;
	transition: 0.4s transform;
	transition: 0.4s transform, 0.4s -webkit-transform;
	text-transform: uppercase;
	font-size: 10px;
}
.main-nav:hover > ul > li > a > span:not(.fake-svg) {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.svg-logo {
	width: 173px;
	height:126px;
	float: left;

}
.svg-logo path{

	fill: #800000;

}
.myName{
margin-left: 300px;
font-family: 'Press Start 2P', cursive;
color:  #800000;
text-align: center;
display: inline-block;
}

.mainPageWindow{
width: 100%;
height: 125vh;
background-color: white;
margin-left: 4.5vw;


}
.footer_svg{
width: 100%;
display: table;
margin: 0 auto;
}
.footer_list >li{
	display: inline;
	float: right;
	padding-right: 100px;
	height: 50.1px;
}
.linkedin{
	width: 45px;
	height: 45px;
}
.youtube{

	width: 45px;
	height: 45px;
}
.twitter{
	width: 45px;
	height: 45px;
}
.github{
	width: 45px;
	height: 45px;
}
.card-img-top{
	height: auto;


}
.card{
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}
.card-img{
	width: 91%;
	height: auto;
}
.tab-content{
	padding-top: 1em;
}
.CloseTag{
	text-align: center;
	color: white;
}
.show > ul >li{
	height: 2em;
	width: 10em;
	list-style: disc;
}
.me{
	
	margin: 2rem;
	text-align: justify;

}
.myPic{
margin-right: 2rem;
float: left;
}
.mePic{
	width: 580px;
	height: 800px;
	padding-left: 1rem;
	
}
.engineTalk{
text-align: justify;
width: 94%;

}
.who{
	text-align: center;
}
.tagLine{
	text-align: center;
}
.aboutMeDiv{
	padding: 2rem;
	width:30%;
}
.replay{
	height: 13rem;
	width: 13rem;
	margin-left: 3rem;
}
.tormentImage{
width: 31%;

}
.tormentVideos1{
	padding: 1rem;
	float: left;
	display: grid;
}
.tormentVideos2{
	padding: 1rem;
	float: right;
	display: grid;
}
.tormentVideos3{
	padding: 1rem;
	float: left;
	display: grid;
}
.TormentVideos{	
	height: 50rem;
}
.homepageCard{
	height: auto;
}
.card-header-tabs{
	padding-left: 1rem;
}


@media screen and (max-width: 1440px){
	.mainPageWindow{
		margin-left: 7.5vw;
	}
}
